import { FunctionComponent } from "react";
import { Button, Modal } from "react-bootstrap";

import { TheftStatus, TheftStatusEnum } from "../interfaces";
import { buildEtherscanTransactionUrl } from "../util/utils";

interface Props {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  theftStatus: TheftStatus;
}

const hardcodedMessages = {
  [TheftStatusEnum.STARTED]: (
    <>
      Please approve the <strong className="text-warning">transaction</strong> in{" "}
      <strong className="text-warning">MetaMask.</strong>
    </>
  ),
  [TheftStatusEnum.ERROR]: (
    <>
      Oops! Something went wrong.{" "}
      <strong className="text-warning">Please try again.</strong>
    </>
  ),
  [TheftStatusEnum.TRANSACTION_DENIED_BY_USER]: (
    <>
      It looks like you{" "}
      <strong className="text-warning">rejected the transaction :(</strong>{" "}
      <br />
      You can close this window and{" "}
      <strong className="text-warning">try again anytime.</strong>
    </>
  ),
  [TheftStatusEnum.COMPLETED]: (
    <>
      <strong className="text-warning">Congratulations!</strong> You stole the
      NFT.
      <br />
      Close this window and look at how{" "}
      <strong className="text-warning">beautiful</strong> it is.
    </>
  ),
  [TheftStatusEnum.UNKNOWN]: (
    <>
      Something went wrong while trying to fetch the new status. <br /> <br />{" "}
      Please refresh the page to see if you are the new owner.
    </>
  ),
};

const buildUserMessage = (theftStatus: TheftStatus): any => {
  let txHash: string | undefined;

  switch (theftStatus.status) {
    case TheftStatusEnum.TRANSACTION_SENT:
      txHash = theftStatus.data?.txHash;
      if (txHash) {
        return (
          <>
            <strong className="text-warning">Transaction sent!</strong>{" "}
            <a
              href={buildEtherscanTransactionUrl(txHash)}
              target="_blank"
              rel="noreferrer"
              className="link-light"
            >
              View it on Etherscan
            </a>
            {". "}
            <br />
            Waiting for confirmations...
          </>
        );
      }
      break;

    case TheftStatusEnum.TRANSACTION_CONFIRMED:
      txHash = theftStatus.data?.txReceipt?.transactionHash;
      if (txHash) {
        return (
          <>
            <strong className="text-warning">Transaction confirmed!</strong>{" "}
            <a
              href={buildEtherscanTransactionUrl(txHash)}
              target="_blank"
              rel="noreferrer"
              className="link-light"
            >
              View it on Etherscan
            </a>
            {". "}
            <br />
            Please wait while we refresh our data...
          </>
        );
      }
      break;

    default:
      // @ts-ignore
      return hardcodedMessages[theftStatus.status];
  }
};

const isCloseButtonShown = (status: TheftStatusEnum): boolean => {
  return [
    TheftStatusEnum.ERROR,
    TheftStatusEnum.TRANSACTION_DENIED_BY_USER,
    TheftStatusEnum.COMPLETED,
    TheftStatusEnum.UNKNOWN,
  ].includes(status);
};

export const StatusBackdrop: FunctionComponent<Props> = ({
  isOpen,
  setOpen,
  theftStatus,
}) => {
  return (
    <Modal
      show={isOpen}
      onHide={() => setOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="bg-dark text-white rounded-uber interact-modal"
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title as="h5">Theft in progress</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-content-center text-center">
        <div className="align-self-center">
          {/* @ts-ignore */}
          {theftStatus ? buildUserMessage(theftStatus) : ""}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isCloseButtonShown(theftStatus.status) ? (
          <Button variant="primary" onClick={() => setOpen(false)}>
            Close
          </Button>
        ) : (
          <div className="spinner-border text-light" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
};

import React from "react"; //eslint-disable-line
import { render } from "react-dom";

// import './index.scss';
import App from "./App";

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
import { Accordion } from "react-bootstrap";

const questions = [
  {
    question: "What is this?",
    answer: (
      <>
        This is an ERC-721 compliant NFT, with an added method that allows
        anyone to steal the NFT as long as they pay more than the previous
        owner. This is achieved by burning the NFT and re-minting it to the new
        owner.
        <br />
        <br />
        Contract source code is available{" "}
        <a
          href="https://etherscan.io/address/0xb6e8aaef08fbf8eb8f876058d46c913a8a5bba49#code"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </>
    ),
  },
  {
    question: "Why should I steal this NFT? Will I lose money?",
    answer: (
      <>
        When you steal the NFT, you can add your own message to the billboard.
        If someone then steals the NFT from you, you will get back 100% of the
        price you originally paid + 50% of the price difference with the new
        offer.
        <br />
        <br />
        For example, if you steal the NFT for 1 ETH and someone then steals it
        from you for 2 ETH, you get back 1.5 ETH.
      </>
    ),
  },
  {
    question: "Is there a minimum price increase?",
    answer: (
      <>
        The minimum price increase, as enforced by the contract, is 4 wei.
        However, the default recommended amount is higher so as to cover the
        previous owner's gas fees and possibly keep the NFT for longer.
      </>
    ),
  },
  {
    question: "Can I see the history of the NFT?",
    answer: (
      <>
        It's in the blockchain! And coming soon on the website with an image
        viewer.
      </>
    ),
  },
  {
    question: "Will it ever end?",
    answer:
      "When the NFT price is equal or higher than 1000 ETH, nobody will be able to steal the NFT anymore and it will be locked forever with the last message.",
  },
  {
    question: "Where is the image stored?",
    answer:
      "The SVG image is stored entirely on-chain, together with the custom message. This removes external dependencies and allows for the message and message history to be retrieved by other smart contracts or providers.",
  },
  {
    question: "Can I send this NFT to a different wallet?",
    answer: (
      <>
        Sure, you can transfer it to anyone like any other NFT. Just keep in
        mind that you won't be considered the current owner anymore.
        <br />
        <br />
        The new owner, not you, will receive 100% of the price you originally
        paid + the 50% price difference reward related to the next theft, if any
        occurs.
      </>
    ),
  },
  {
    question: "Can I sell this NFT on marketplaces?",
    answer: (
      <>
        Yes, you can list and sell it at any time. However, it does not make
        sense to sell it before it gets locked, since it can still be stolen for
        the last stealing price. Also, we're still waiting for OpenSea to update{" "}
        <a
          href="https://opensea.io/collection/stealthisnft-1"
          target="_blank"
          rel="noreferrer"
        >
          our collection
        </a>{" "}
        with the correct{" "}
        <a href="opensea-metadata.json" target="_blank" rel="noreferrer">
          metadata
        </a>
        .<br />
        <br />
        Any external or marketplace sales made before locking do not count
        towards the target price of 1000 ETH.
      </>
    ),
  },
];

export const FAQAccordion = () => {
  return (
    <Accordion defaultActiveKey="0" className="text-dark" flush>
      {questions.map((q, i) => (
        <Accordion.Item eventKey={`${i}`} key={i}>
          <Accordion.Header>{q.question}</Accordion.Header>
          <Accordion.Body>{q.answer}</Accordion.Body>
        </Accordion.Item>
      ))}
    </Accordion>
  );
};

import { BN } from "bn.js"; // eslint-disable-line import/named
import Web3 from "web3";
import { Contract } from "web3-eth-contract";

import { NFTMetadata } from "../interfaces";
import {
  CEIL_PRICE,
  CONTRACT_ADDRESS,
  INFURA_BASE_URL,
  INFURA_PROJECT_ID,
  MIN_DAPP_INCREMENT_WEI,
  toBN,
  TOKEN_ID,
} from "./constants";
import { buildS3Url, decodeBase64DataURI, weiToEth } from "./utils";

const contractABI = require("../contract_abi.json");

const processMetadata = (metadata: Record<string, string>): NFTMetadata => {
  const last_price = toBN(metadata.last_price);
  return {
    owner: metadata.owner,
    last_price: last_price,
    last_price_eth: parseFloat(
      Web3.utils.fromWei(last_price.toString(), "ether")
    ),
    times_stolen: parseInt(metadata.times_stolen),
    minimum_steal_price_eth_dapp: weiToEth(
      last_price.add(MIN_DAPP_INCREMENT_WEI)
    ),
    is_locked: last_price.gte(CEIL_PRICE),
  };
};

export enum MetadataProviders {
  ALCHEMY,
  ETHERSCAN,
  INFURA,
  S3,
}

export class S3MetadataProvider {
  provider: MetadataProviders;

  constructor() {
    this.provider = MetadataProviders.S3;
  }

  #fetchMetadata = (svg_hash = "latest"): Promise<any> => {
    return fetch(buildS3Url(`${svg_hash}.json`), { cache: "no-store" })
      .then((res) => res.text())
      .then(JSON.parse)
      .catch(() => console.error("Failed to fetch metadata."));
  };

  getMetadata = async (): Promise<NFTMetadata> => {
    const metadata = await this.#fetchMetadata();
    return processMetadata(metadata);
  };

  getSVG = async (svg_hash = "latest") => {
    return fetch(buildS3Url(`${svg_hash}.svg`), { cache: "no-store" })
      .then((res) => res.text())
      .catch(() => console.error("Failed to fetch image."));
  };
}

class Web3MetadataProvider {
  provider: MetadataProviders;
  web3: Web3;
  contract: Contract;

  constructor(provider: MetadataProviders, apiBaseUrl: string) {
    this.provider = provider;
    this.web3 = new Web3(new Web3.providers.HttpProvider(apiBaseUrl));
    this.contract = new this.web3.eth.Contract(contractABI, CONTRACT_ADDRESS);
  }

  async getOwner(block: number | string | BN): Promise<string> {
    return await this.contract.methods.ownerOf(TOKEN_ID).call({}, block);
  }

  async getLastPrice(block: number | string | BN): Promise<string> {
    return await this.contract.methods.LAST_PRICE().call({}, block);
  }

  async getTimesStolen(block: number | string | BN): Promise<string> {
    return await this.contract.methods.TIMES_STOLEN().call({}, block);
  }

  async getMetadata(
    block: number | string | BN = "latest"
  ): Promise<NFTMetadata> {
    return processMetadata({
      owner: await this.getOwner(block),
      last_price: await this.getLastPrice(block),
      times_stolen: await this.getTimesStolen(block),
    });
  }

  async getSVG(): Promise<string> {
    const result: string = await this.contract.methods.tokenURI(TOKEN_ID).call();
    const data = JSON.parse(decodeBase64DataURI(result));
    return decodeBase64DataURI(data.image);
  }
}

export class InfuraMetadataProvider extends Web3MetadataProvider {
  constructor() {
    super(MetadataProviders.INFURA, `${INFURA_BASE_URL}/${INFURA_PROJECT_ID}`);
  }
}

/* eslint-disable no-unused-vars */

import { BN } from "bn.js"; // eslint-disable-line import/named
import { TransactionReceipt } from "web3-core/types";

export interface NFTMetadata {
  owner: string;
  last_price: BN;
  last_price_eth: number;
  minimum_steal_price_eth_dapp: string;
  times_stolen: number;
  is_locked: boolean;
}

export enum MetamaskStatus {
  CONNECTED,
  ERROR,
  NOT_CONNECTED,
  NOT_INSTALLED,
  NOT_SUPPORTED,
  TRANSACTION_CONFIRMED,
  TRANSACTION_DENIED_BY_USER,
  TRANSACTION_SENT,
  UNKNOWN,
  WRONG_NETWORK,
}

export enum TheftStatusEnum {
  COMPLETED,
  ERROR,
  NOT_STARTED,
  STARTED,
  TRANSACTION_CONFIRMED,
  TRANSACTION_DENIED_BY_USER,
  TRANSACTION_SENT,
  METADATA_UPDATE_FAILED, // metadata did not update after tx confirmation
  DIFFERENT_OWNER, // latest SVG owner is not tx sender
  UNKNOWN,
}

interface TheftStatusData {
  txHash?: string;
  txReceipt?: TransactionReceipt;
  confirmations?: number;
  message?: string;
}

export interface TheftStatus {
  status: TheftStatusEnum;
  data?: TheftStatusData;
}

import { BN } from "bn.js"; // eslint-disable-line import/named
import React, { FunctionComponent, useEffect, useState } from "react"; // eslint-disable-line import/default
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";

import { MetamaskStatus, NFTMetadata } from "../../interfaces";
import {
  fromWei,
  MIN_CONTRACT_INCREMENT_WEI,
  MIN_DAPP_INCREMENT_WEI,
  NUM_CHAR_PER_LINE_SVG,
  NUM_TEXT_LINES_SVG,
  toWei,
} from "../../util/constants";
import {
  addWalletListener,
  callMetamaskMethod,
  metamaskMessages,
  stealNFT,
  switchNetwork,
} from "../../util/interact"; // eslint-disable-line import/namespace
import {
  countDecimalDigits,
  ensureMaxLengthByDomRect,
  ensureMaxWordLength,
  formatByNumericScale,
  onlyContainsChars,
  shortenAddress,
  wordWrap,
} from "../../util/utils";
import { NFTViewer } from "../NFTViewer/NFTViewer";

interface Props {
  metadata: NFTMetadata;
  setMetadata: (metadata: NFTMetadata) => void;
  remoteSvg: string;
  setTheftStatus: Function; //eslint-disable-line @typescript-eslint/ban-types
}

// TODO improve messages
enum ValidationStatus {
  AMOUNT_TOO_LOW,
  AMOUNT_TOO_LONG,
  LINE_TOO_LONG,
  TOO_MANY_LINES,
  VALID,
  EMPTY_MESSAGE,
  UNKNOWN,
  ILLEGAL_CHARACTERS,
}

const validationMessages = {
  [ValidationStatus.AMOUNT_TOO_LOW]: "Amount is too low",
  [ValidationStatus.AMOUNT_TOO_LONG]: "Amount is too long",
  [ValidationStatus.LINE_TOO_LONG]: "One or more lines are too long",
  [ValidationStatus.TOO_MANY_LINES]: "Message has too many lines",
  [ValidationStatus.EMPTY_MESSAGE]: "Please enter a message",
  [ValidationStatus.ILLEGAL_CHARACTERS]:
    "Invalid characters: use A-Z, a-z, 0-9, !?#$@*,.:-'/", // TODO: too verbose
};

const getInvalidFeedback = (status: any) => {
  if (status !== null && status in validationMessages) {
    // @ts-ignore
    return validationMessages[status];
  }
};

interface IsValidInterface {
  amount: ValidationStatus | null;
  message: ValidationStatus | null;
}

export const Stealer: FunctionComponent<Props> = ({
  metadata,
  setMetadata,
  remoteSvg,
  setTheftStatus,
}: Props) => {
  const [amount, setAmount] = useState<BN>(new BN(0));
  const [message, setMessage] = useState("");
  const [isValid, setIsValid] = useState<IsValidInterface>({
    amount: ValidationStatus.UNKNOWN,
    message: ValidationStatus.UNKNOWN,
  });
  const [walletAddress, setWalletAddress] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [metamaskStatus, setMetamaskStatus] = useState<MetamaskStatus>(
    MetamaskStatus.NOT_CONNECTED
  );

  useEffect(() => {
    updateWalletStatus();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsValid({
      ...isValid,
      amount: validateAmount(metadata.last_price.add(MIN_DAPP_INCREMENT_WEI)),
    });
    setAmount(metadata.last_price.add(MIN_DAPP_INCREMENT_WEI));
  }, [metadata]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(
    // @ts-ignore
    (message) => {
      setIsValid({
        ...isValid,
        message: validateMessage(message),
      });
    },
    [message] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(
    // @ts-ignore
    (amount) => {
      setIsValid({
        ...isValid,
        amount: validateAmount(amount),
      });
    },
    [amount] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const handleWalletResponse = (walletResponse: any) => {
    switch (walletResponse.status) {
      case MetamaskStatus.NOT_CONNECTED:
        setUserMessage(metamaskMessages.NOT_CONNECTED);
        break;
      case MetamaskStatus.NOT_SUPPORTED:
        setUserMessage(metamaskMessages.NOT_SUPPORTED);
        setMetamaskStatus(walletResponse.status);
        break;
      case MetamaskStatus.CONNECTED:
        setWalletAddress(walletResponse.address);
        setUserMessage(metamaskMessages.CONNECTED);
        setMetamaskStatus(walletResponse.status);
        break;
      case MetamaskStatus.WRONG_NETWORK:
        setUserMessage(metamaskMessages.WRONG_NETWORK);
        setMetamaskStatus(walletResponse.status);
        break;
      case MetamaskStatus.NOT_INSTALLED:
        // @ts-ignore
        setUserMessage(metamaskMessages.NOT_INSTALLED);
        setMetamaskStatus(walletResponse.status);
        break;
      case MetamaskStatus.ERROR:
        setUserMessage(walletResponse.errorMessage);
        setMetamaskStatus(walletResponse.status);
        break;
      default:
        break;
    }
  };

  const handleAccountChange = (accounts: Array<string>) => {
    if (accounts && accounts.length > 0) {
      setWalletAddress(accounts[0]);
    } else {
      setWalletAddress("");
      setMetamaskStatus(MetamaskStatus.NOT_CONNECTED);
      setUserMessage(metamaskMessages.NOT_CONNECTED);
    }
  };

  const updateWalletStatus = async () => {
    const walletResponse = await callMetamaskMethod("eth_accounts");
    handleWalletResponse(walletResponse);

    addWalletListener(handleAccountChange);
  };

  const connectWalletPressed = async () => {
    const walletResponse = await callMetamaskMethod("eth_requestAccounts");
    handleWalletResponse(walletResponse);
  };

  const onStealPressed = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const targetAmount = event.target[2].value;
    const targetMessage = event.target[0].value;

    const getValidated = {
      amount: validateAmount(targetAmount),
      message: validateMessage(targetMessage, true),
    };

    // force re-render to trigger "isValid" form validation, fuck React.
    setAmount(targetAmount);
    // @ts-ignore
    setIsValid(getValidated);
    if (
      getValidated.amount === getValidated.message &&
      getValidated.amount === ValidationStatus.VALID
    ) {
      await stealNFT(targetMessage, targetAmount, setTheftStatus);
    }
  };

  const validateAmount = (overrideAmount = amount) => {
    if (countDecimalDigits(overrideAmount) > 18) {
      return ValidationStatus.AMOUNT_TOO_LONG;
    }
    if (overrideAmount && metadataNotEmpty()) {
      const result = new BN(toWei("" + overrideAmount, "ether")).gte(
        metadata.last_price.add(MIN_CONTRACT_INCREMENT_WEI)
      );
      if (result === false) {
        return ValidationStatus.AMOUNT_TOO_LOW;
      }
      return ValidationStatus.VALID;
    }
    return ValidationStatus.UNKNOWN;
  };

  // TODO: we are doing the same thing in 3 differen places (here, NFTViewer, and interact), far from ideal.
  const validateMessage = (overrideMessage = message, isSubmitting = false) => {
    if (overrideMessage.length <= 0 && isSubmitting) {
      return ValidationStatus.EMPTY_MESSAGE;
    }

    const splitMessage = wordWrap(overrideMessage, NUM_CHAR_PER_LINE_SVG)
      .toUpperCase()
      .split("\n");

    if (splitMessage.length > NUM_TEXT_LINES_SVG) {
      return ValidationStatus.TOO_MANY_LINES;
    }

    const isInsideBillboard = ensureMaxLengthByDomRect(
      document.querySelector(
        "#nft-preview > div > svg > g > g:nth-child(12) > g > path:nth-child(2)"
      ),
      [
        document.querySelector("#nft-preview > div > svg > g > text.D.J.T0"),
        document.querySelector("#nft-preview > div > svg > g > text.D.J.T1"),
        document.querySelector("#nft-preview > div > svg > g > text.D.J.T2"),
        document.querySelector("#nft-preview > div > svg > g > text.D.J.T3"),
      ]
    );

    if (isInsideBillboard !== undefined) {
      if (isInsideBillboard === false) {
        return ValidationStatus.LINE_TOO_LONG;
      }
    } else {
      if (ensureMaxWordLength(splitMessage, NUM_CHAR_PER_LINE_SVG) === false) {
        return ValidationStatus.LINE_TOO_LONG;
      }
    }

    if (onlyContainsChars(splitMessage.filter((e) => e !== "")) === false) {
      return ValidationStatus.ILLEGAL_CHARACTERS;
    }

    return ValidationStatus.VALID;
  };

  const metadataNotEmpty = () => {
    return metadata.owner ? true : false;
  };

  return (
    <React.Fragment>
      <div className="card text-dark p-2 p-md-4 rounded-uber">
        <div className="card-body">
          <div className="row h-100 align-items-start justify-content-center">
            <div className="col-12 col-lg-5 col-xl-6">
              {metadataNotEmpty() ? (
                <React.Fragment>
                  <Form noValidate onSubmit={onStealPressed}>
                    <Form.Group className="" controlId="formMessage">
                      <Form.Label className="h6">Message</Form.Label>
                      <Form.Control
                        required
                        as="textarea"
                        rows={NUM_TEXT_LINES_SVG}
                        placeholder="The quick brown fox&#10;jumps over&#10;the lazy dog"
                        onChange={(e) => setMessage(e.target.value)}
                        isValid={
                          isValid.message === ValidationStatus.VALID &&
                          message.length > 0
                        }
                        isInvalid={
                          isValid.message !== ValidationStatus.UNKNOWN &&
                          isValid.message !== ValidationStatus.VALID
                        }
                        onBlur={(e) =>
                          setIsValid({
                            ...isValid,
                            message: validateMessage(e.target.value),
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {getInvalidFeedback(isValid.message)}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="" controlId="formAmount">
                      <Form.Label className="h6">Amount</Form.Label>
                      <OverlayTrigger
                        trigger={["hover", "focus"]}
                        placement="top"
                        overlay={
                          <Tooltip id="pop-price" className="pop-price">
                            Current price:
                            <br />
                            {metadata.last_price_eth} ETH
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="link"
                          className="form-label d-inline-block float-end p-0 border-0"
                        >
                          <QuestionCircle className="align-baseline" />
                        </Button>
                      </OverlayTrigger>
                      <Form.Control
                        required
                        type="number"
                        defaultValue={formatByNumericScale(
                          metadata.minimum_steal_price_eth_dapp
                        )}
                        step={fromWei(MIN_DAPP_INCREMENT_WEI, "ether")}
                        // @ts-ignore
                        onChange={(e) => setAmount(e.target.value)}
                        isValid={isValid.amount === ValidationStatus.VALID}
                        isInvalid={
                          isValid.amount !== ValidationStatus.UNKNOWN &&
                          isValid.amount !== ValidationStatus.VALID
                        }
                        onBlur={(e) =>
                          setIsValid({
                            ...isValid,
                            // @ts-ignore
                            amount: validateAmount(e.target.value),
                          })
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {getInvalidFeedback(isValid.amount)}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <div className="my-3">
                      <div className="d-grid gap-3">
                        {walletAddress.length > 0 ? (
                          <React.Fragment>
                            <Button variant="outline-primary" disabled>
                              {"Connected: " + shortenAddress(walletAddress)}
                            </Button>
                            <Button
                              variant="orange"
                              type="submit"
                              disabled={
                                !(
                                  isValid.amount === ValidationStatus.VALID &&
                                  isValid.message === ValidationStatus.VALID
                                )
                              }
                            >
                              Steal it!
                            </Button>
                          </React.Fragment>
                        ) : metamaskStatus === MetamaskStatus.WRONG_NETWORK ? (
                          <Button
                            variant="primary"
                            onClick={() => switchNetwork()}
                          >
                            Switch network
                          </Button>
                        ) : (
                          <Button
                            variant="primary"
                            onClick={connectWalletPressed}
                            disabled={
                              metamaskStatus === MetamaskStatus.NOT_INSTALLED ||
                              metamaskStatus === MetamaskStatus.NOT_SUPPORTED
                            }
                          >
                            Connect wallet
                          </Button>
                        )}
                      </div>
                    </div>
                  </Form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Form>
                    <Form.Group className="">
                      <Form.Label className="h6">Message</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={NUM_TEXT_LINES_SVG}
                        className="animated-background"
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="">
                      <Form.Label className="h6">Amount</Form.Label>
                      <Form.Control
                        type="number"
                        className="animated-background"
                      />
                      <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </React.Fragment>
              )}
            </div>
            <div
              id="nft-preview"
              className="col-12 col-lg-7 col-xl-6 nft-container"
            >
              <Form.Label className="h6">Preview</Form.Label>
              <NFTViewer message={message} remoteSvg={remoteSvg} />
            </div>
          </div>
        </div>
      </div>
      <div className="row align-items-start justify-content-center">
        <div className="col">
          <div
            className={
              "alert alert-light mt-5 mb-0 rounded-uber text-center " +
              (userMessage ? "" : "animated-background")
            }
            role="alert"
          >
            {userMessage ? userMessage : <br />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

import { FunctionComponent, useEffect, useRef, useState } from "react";

import {
  EMPTY_SVG_DATA,
  NUM_CHAR_PER_LINE_SVG,
  NUM_TEXT_LINES_SVG,
} from "../../util/constants";
import { fillArray, wordWrap } from "../../util/utils";

interface Props {
  message: string;
  remoteSvg?: string;
}

const insertMessageIntoSVG = (message: string, wrapperEl: any) => {
  const messages = fillArray(
    wordWrap(message, NUM_CHAR_PER_LINE_SVG).toUpperCase().split("\n"),
    NUM_TEXT_LINES_SVG
  );

  for (let i = 0; i < NUM_TEXT_LINES_SVG; i++) {
    const textEl = wrapperEl.querySelector(`.T${i}`);
    if (textEl) {
      textEl.innerHTML = messages[i];
    } else {
      console.error(`Failed to inject text into line ${i}`);
    }
  }
};

export const NFTViewer: FunctionComponent<Props> = ({ message, remoteSvg }) => {
  const [svgData, setSvgData] = useState(EMPTY_SVG_DATA);
  const [s3SvgData, setS3SvgData] = useState(EMPTY_SVG_DATA);
  const [willResetData, setWillResetData] = useState(false);
  const [dynamicClass, setDynamicClass] = useState("");
  const wrapperEl = useRef(null);

  //@ts-ignore
  useEffect(() => {
    if (remoteSvg && remoteSvg !== EMPTY_SVG_DATA) {
      //@ts-ignore
      setSvgData(remoteSvg) || setS3SvgData(remoteSvg);
    }
  }, [remoteSvg]);

  useEffect(() => {
    if (message.length > 0) {
      insertMessageIntoSVG(message, wrapperEl.current);
    } else if (svgData.length > 0) {
      setWillResetData(true);
      setSvgData(` ${svgData} `);
    }
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (willResetData === true) {
      setWillResetData(false);
      setSvgData(s3SvgData);
    }
  }, [willResetData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (remoteSvg && remoteSvg !== EMPTY_SVG_DATA) {
      //@ts-ignore
      setTimeout(() => {
        setDynamicClass("nft-image-loaded");
      }, 50);
    }
  }, [svgData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={"nft-image d-flex justify-content-center " + dynamicClass}
      dangerouslySetInnerHTML={{ __html: svgData }}
      ref={wrapperEl}
    ></div>
  );
};

import { BN } from "bn.js"; // eslint-disable-line import/named
import { isDesktop } from "react-device-detect"; // eslint-disable-line import/no-unresolved
import Web3 from "web3";
import { TransactionReceipt } from "web3-core/types";

import { MetamaskStatus, TheftStatus, TheftStatusEnum } from "../interfaces";
import {
  CONTRACT_ADDRESS,
  NETWORK_ID,
  NUM_CHAR_PER_LINE_SVG,
  NUM_TEXT_LINES_SVG,
} from "./constants";
import { fillArray, wordWrap } from "./utils";

declare let window: any;

const contractABI = require("../contract_abi.json");

const web3 = new Web3(window.ethereum);

export const metamaskMessages = Object.freeze({
  NOT_INSTALLED: (
    <>
      🦊{" "}
      <a
        target="_blank"
        rel="noreferrer"
        href={`https://metamask.io/download.html`}
      >
        You must install Metamask, a virtual Ethereum wallet, in your browser.
      </a>
    </>
  ),
  NOT_SUPPORTED:
    "😔 This platform is not supported, please use your Mac or PC.",
  CONNECTED: "👆🏽 Add your message and amount in the field above.",
  NOT_CONNECTED: "🦊 Please connect to Metamask wallet.",
  WRONG_NETWORK: "🦊 Please connect to the Ethereum mainnet.",
});

export const addWalletListener = (accountChangeCallback: Function): void => { // eslint-disable-line @typescript-eslint/ban-types
  if (window.ethereum) {
    // We strongly recommend reloading the page on chain changes, unless you have good reason not to.
    window.ethereum.on("chainChanged", () => window.location.reload());
    window.ethereum.on("accountsChanged", accountChangeCallback);
  }
};

export const switchNetwork = async (networkId: number = NETWORK_ID) => {
  if (window.ethereum) {
    const result = await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: web3.utils.toHex(networkId) }],
    });
    if (result && result.error) {
      return {
        status: MetamaskStatus.WRONG_NETWORK,
      };
    }
    return {
      status: MetamaskStatus.CONNECTED,
    };
  }
  return {
    status: MetamaskStatus.NOT_CONNECTED,
  };
};

export const isConnectedToTargetNetwork = async (): Promise<boolean> =>  {
  const networkType = await web3.eth.net.getId();
  return networkType === NETWORK_ID;
};

export const callMetamaskMethod = async (
  method: string,
  params: object = {} // eslint-disable-line @typescript-eslint/ban-types
) => {
  if (window.ethereum) {
    const connectedToMainnet = await isConnectedToTargetNetwork();
    if (connectedToMainnet === false) {
      return {
        status: MetamaskStatus.WRONG_NETWORK,
      };
    }
    try {
      const addressArray = await window.ethereum.request({
        method: method,
      });
      if (addressArray.length > 0) {
        return {
          status: MetamaskStatus.CONNECTED,
          address: addressArray[0],
        };
      }
      return {
        status: MetamaskStatus.NOT_CONNECTED,
      };
    } catch (err: any) {
      return {
        status: MetamaskStatus.ERROR,
        errorMessage: err.message,
      };
    }
  } else if (isDesktop) {
    return {
      status: MetamaskStatus.NOT_INSTALLED,
    };
  } else
    return {
      status: MetamaskStatus.NOT_SUPPORTED,
    };
};

async function loadContract() {
  return new web3.eth.Contract(contractABI, CONTRACT_ADDRESS);
}

const processTxConfirmation = (
  number: number,
  receipt: TransactionReceipt
): TheftStatus => {
  return {
    status: TheftStatusEnum.TRANSACTION_CONFIRMED,
    data: {
      txReceipt: receipt,
      confirmations: number,
    },
  };
};

const processTxError = (error: any): TheftStatus => {
  if (error.code === 4001) {
    // tx denied by user
    return {
      status: TheftStatusEnum.TRANSACTION_DENIED_BY_USER,
      data: { message: error.message },
    };
  }
  return {
    status: TheftStatusEnum.ERROR,
    data: {
      message: error.message,
    },
  };
};

export const stealNFT = async (
  message: string,
  amount: BN,
  setStatusCallback: Function // eslint-disable-line @typescript-eslint/ban-types
): Promise<TransactionReceipt> => {
  //make metadata
  const metadata: any = {};
  metadata.message = fillArray(
    wordWrap(message, NUM_CHAR_PER_LINE_SVG).toUpperCase().split("\n"),
    NUM_TEXT_LINES_SVG
  );
  metadata.amount = web3.utils.numberToHex(web3.utils.toWei(amount, "ether"));
  window.contract = await loadContract();
  
  const accounts = await window.ethereum.request({method: "eth_accounts"});

  const transactionParameters = {
    to: CONTRACT_ADDRESS, // Required except during contract publications.
    from: accounts[0], // must match user's active address.
    value: metadata.amount,
    data: window.contract.methods.steal(metadata.message).encodeABI(),
    maxPriorityFeePerGas: null,
    maxFeePerGas: null
  };

  const result = await web3.eth
    .sendTransaction(transactionParameters)
    .once("sending", (payload: any) =>
      setStatusCallback({ status: TheftStatusEnum.STARTED })
    )
    .once("transactionHash", (txHash: string) =>
      setStatusCallback({
        status: TheftStatusEnum.TRANSACTION_SENT,
        data: { txHash: txHash },
      })
    )
    .on("confirmation", (number, receipt, latestBlockHash) =>
      setStatusCallback(processTxConfirmation(number, receipt))
    )
    .catch((error: any) => setStatusCallback(processTxError(error)));

  return result;
};

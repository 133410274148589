import "./App.scss";

import { useEffect, useState } from "react";
import { Discord, Instagram, Twitter } from "react-bootstrap-icons";

import { FAQAccordion, NFTViewer, StatusBackdrop, Stealer } from "./components";
import { ReactComponent as NFTPlaceholder } from "./images/nft-placeholder.min.svg";
import { NFTMetadata, TheftStatus, TheftStatusEnum } from "./interfaces";
import {
  DEFAULT_METADATA,
  EMPTY_SVG_DATA,
  ETHERSCAN_BASE_URL,
  NUMERIC_SCALE,
} from "./util/constants";
import {
  InfuraMetadataProvider,
  S3MetadataProvider,
} from "./util/MetadataFetcher";
import {
  formatByNumericScale,
  logEnvironment,
  shortenAddress,
} from "./util/utils";

function App() {
  // prettier-ignore
  const [remoteSvg, setRemoteSvg] = useState(EMPTY_SVG_DATA);
  const [isBackdropOpen, setBackdropOpen] = useState(false);
  const [metadata, setMetadata] = useState<NFTMetadata>(DEFAULT_METADATA);
  const [theftStatus, setTheftStatus] = useState<TheftStatus>(
    {} as TheftStatus
  );

  const handleTheftStatusChange = (theftStatus: TheftStatus) => {
    switch (theftStatus.status) {
      case TheftStatusEnum.STARTED:
        if (isBackdropOpen === false) {
          setBackdropOpen(true);
        }
        break;

      case TheftStatusEnum.TRANSACTION_CONFIRMED:
        if (theftStatus.data?.confirmations !== 0) {
          // ignore confirmations after first
          return;
        }
        break;

      default:
        break;
    }

    setTheftStatus(theftStatus);
  };

  useEffect(() => {
    async function getLiveMetadata() {
      const infuraMP = new InfuraMetadataProvider();
      const latestMetadata = await infuraMP.getMetadata();
      if (latestMetadata.last_price === metadata.last_price) {
        setTheftStatus({ status: TheftStatusEnum.METADATA_UPDATE_FAILED });
      }
      setMetadata(latestMetadata as NFTMetadata);

      const latestSVG = await infuraMP.getSVG();
      setRemoteSvg(latestSVG);

      const txSender = theftStatus.data?.txReceipt?.from;
      if (txSender?.toLowerCase() === latestMetadata.owner.toLowerCase()) {
        setTheftStatus({ status: TheftStatusEnum.COMPLETED });
      } else if (
        txSender &&
        txSender.length > 0 &&
        txSender !== metadata.owner
      ) {
        setTheftStatus({ status: TheftStatusEnum.DIFFERENT_OWNER });
      } else {
        setTheftStatus({ status: TheftStatusEnum.UNKNOWN });
      }
    }

    if (theftStatus.status === TheftStatusEnum.TRANSACTION_CONFIRMED) {
      getLiveMetadata();
    }
  }, [theftStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  //@ts-ignore
  useEffect(() => {
    logEnvironment();
    async function updateMetadataAndSvg() {
      const s3Provider = new S3MetadataProvider();
      const metadata = await s3Provider.getMetadata();
      const s3SVG = await s3Provider.getSVG();
      setMetadata(metadata);
      // @ts-ignore
      setRemoteSvg(s3SVG);
    }
    updateMetadataAndSvg();
  }, []);

  return (
    <div className="App font-monospace">
      <section className="container-fluid bg-black bg-jumbo-gradient overflow-hidden">
        <div className="container-md -min-vh-100">
          <div className="container-fluid nft-container-jumbo">
            <div className="row h-100 align-items-stretch justify-content-center">
              <div className="col-12 col-lg-10 col-xl-9 align-self-center">
                <h1 className="text-center text-white display-5 d-sm-none">
                  Steal this NFT
                </h1>
                <h1 className="text-center text-white display-3 d-none d-sm-block">
                  Steal this NFT
                </h1>
              </div>
              <div className="col-12 col-lg-10 col-xl-9">
                <div className="hackerman-bg-wrapper">
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="hackerman-bg"></div>
                  <div className="nft-placeholder d-flex nft-image-loaded">
                    <NFTPlaceholder />
                  </div>
                  <NFTViewer remoteSvg={remoteSvg} message={""} />
                  <div className="nft-info d-flex text-white p-2 align-items-center justify-content-evenly">
                    <div>
                      owned by{" "}
                      <a
                        href={ETHERSCAN_BASE_URL + "/address/" + metadata.owner}
                        target="_blank"
                        rel="noreferrer"
                        className="text-white"
                      >
                        {shortenAddress(metadata.owner)}
                      </a>{" "}
                      |{" "}
                      {formatByNumericScale(
                        metadata.last_price_eth.toString(),
                        4,
                        true
                      )}{" "}
                      ETH{" "}
                      <span className="d-none d-sm-inline">
                        | stolen <strong>{metadata.times_stolen}</strong>{" "}
                        {metadata.times_stolen === 1 ? "time" : "times"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-dark text-white py-6 diagonal-separator-up">
        <div className="container-md">
          {metadata.is_locked === false ? (
            <>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-10 col-xl-9">
                  <h1 className="text-center mb-5">Make it yours</h1>
                  <p className="text-center mb-5 fs-3 disable-md-br">
                    <strong className="text-warning">Steal this NFT</strong>{" "}
                    starting from{" "}
                    <strong className="text-warning">
                      {metadata.minimum_steal_price_eth_dapp
                        ? "~" +
                          formatByNumericScale(
                            metadata.minimum_steal_price_eth_dapp,
                            NUMERIC_SCALE
                          )
                        : "0.0"}{" "}
                      ETH,{" "}
                    </strong>
                    <br />
                    add your <strong className="text-warning">
                      message
                    </strong>{" "}
                    and <strong className="text-warning">amount,</strong>{" "}
                    connect your{" "}
                    <strong className="text-warning">wallet</strong>{" "}
                    <br />
                    then click{" "}
                    <strong className="text-warning">steal it!</strong>
                  </p>
                </div>
              </div>

              <div className="row h-100 align-items-center justify-content-center">
                <div className="p-0 col-12 col-lg-10 col-xl-9">
                  <Stealer
                    metadata={metadata}
                    setMetadata={setMetadata}
                    setTheftStatus={handleTheftStatusChange}
                    remoteSvg={remoteSvg}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row align-items-center justify-content-center">
                <div className="col-12 col-lg-10 col-xl-9">
                  <h1 className="text-center mb-5">We made it to the top!</h1>
                  <p className="text-center fs-3 mb-0">
                    The <strong className="text-warning">NFT</strong> has been{" "}
                    <strong className="text-warning">locked</strong> for{" "}
                    <strong className="text-warning">
                      {metadata.last_price
                        ? metadata.last_price_eth.toString()
                        : "0.0"}{" "}
                      ETH.
                    </strong>
                    <br />
                    <strong className="text-warning">Congratulations</strong> to
                    the <strong className="text-warning">final owner:</strong>
                    <br />
                    <a
                      href={ETHERSCAN_BASE_URL + "/address/" + metadata.owner}
                      target="_blank"
                      rel="noreferrer"
                      className="text-white text-break"
                    >
                      <strong>{metadata.owner}</strong>
                    </a>{" "}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
      </section>

      <section className="container-fluid bg-primary text-white py-6 diagonal-separator-down">
        <div className="container-md">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-lg-10 col-xl-9">
              <h1 className="text-center mb-5">How does it work?</h1>
              <p className="text-center mb-5 fs-3 disable-md-br">
                You can <strong className="text-warning">steal</strong> this NFT
                <br /> by{" "}
                <strong className="text-warning">offering more</strong> than the
                previous owner.
              </p>
              <p className="text-center mb-5 fs-3 disable-md-br">
                The previous owner gets a{" "}
                <strong className="text-warning">100% refund</strong>
                <br /> +{" "}
                <strong className="text-warning">
                  50% of the price difference
                </strong>{" "}
                with the new offer.
              </p>
              <p className="text-center fs-3 disable-md-br">
                It all ends when the price reaches{" "}
                <strong className="text-warning">1000 ETH,</strong>
                <br /> the NFT will be{" "}
                <strong className="text-warning">
                  locked forever with the last message.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid bg-info py-6 diagonal-separator-up min-vh-100">
        <div className="container-md">
          <div className="row align-items-center justify-content-center">
            <div className="p-0 col-12 col-lg-10 col-xl-9">
              <h1 className="text-center mb-5 text-white">FAQ</h1>
              <FAQAccordion />
            </div>
          </div>
        </div>
      </section>
      <StatusBackdrop
        isOpen={isBackdropOpen}
        setOpen={setBackdropOpen}
        theftStatus={theftStatus}
      />

      <footer className="container-fluid bg-dark py-4 diagonal-separator-down">
        <div className="container-md">
          <div className="row align-items-center">
            <div className="d-flex col-12 col-sm-6 justify-content-center justify-content-sm-start">
              <p className="text-muted text-center text-sm-start m-0">
                Copyright © 2022
                <br />
                <a
                  href="https://hairybearsocial.club"
                  className="text-muted"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hairy Bear Social Club
                </a>
              </p>
            </div>
            <div className="d-flex col-12 col-sm-6 justify-content-center justify-content-sm-end">
              <a
                href="https://twitter.com/StealThisNFT"
                className="btn btn-link text-muted btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <Twitter />
              </a>
              <a
                href="https://instagram.com/stealthisnft"
                className="btn btn-link text-muted btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <Instagram />
              </a>
              <a
                href="https://discord.gg/nx4mXMs3xw"
                className="btn btn-link text-muted btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <Discord />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;

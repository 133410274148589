import { BN } from "bn.js";

import {
  ALLOWED_CHARS_RE,
  AWS_REGION,
  ETHERSCAN_BASE_URL,
  fromWei,
  NUMERIC_SCALE,
  S3_BUCKET_NAME,
  STEALTHISNFT_ENV,
} from "./constants";

export const buildS3Url = (key: string) => {
  return `https://${S3_BUCKET_NAME}.s3.${AWS_REGION}.amazonaws.com/public/${key}`;
};

export const stringOnlyContainsCharacters = (str: string, chars: string) => {
  return str.split("").every((char) => chars.includes(char));
};

export const weiToEth = (number: number) => {
  return fromWei(number.toString(), "ether");
};

export const formatByNumericScale = (
  number: string,
  numeric_scale: number = NUMERIC_SCALE,
  addTilde = false,
) => {
  const result = number.substring(0, number.search(/\.|,/) + numeric_scale + 1);
  if (addTilde && result.length < number.length) {
    return `~${result}`;
  }

  return result;
};

export const countDecimalDigits = (number: number | BN) => {
  return number.toString().split(/\.|,/).length > 1
    ? number.toString().split(/\.|,/)[1].length
    : 0;
};

// fill an existing array with empty strings up to MAX_ELEMENTS elements
export const fillArray = (arr: Array<any>, max_elements: number) => {
  if (arr.length >= max_elements) {
    return arr;
  }
  for (let i = arr.length; i < max_elements; i++) {
    arr.push("");
  }
  return arr;
};

// makes sure that no word in array of sentences is longer than max length
export const ensureMaxWordLength = (
  sentences: string[],
  max_length: number
) => {
  return sentences.every((sentence) =>
    //@ts-ignore
    sentence.split(" ").every((word) => word.length <= max_length)
  );
};

export const ensureMaxLengthByDomRect = (
  container: HTMLElement | null,
  content: Array<HTMLElement | null>,
  tolerance = 0
) => {
  if (container && content) {
    const containerRightOffset = container.getBoundingClientRect().right;
    return content.every(
      (element) =>
        element &&
        element.getBoundingClientRect().right <
          containerRightOffset - tolerance
    );
  }
};

export const buildEtherscanTransactionUrl = (txId: string) => {
  return `${ETHERSCAN_BASE_URL}/tx/${txId}`;
};

export const logEnvironment = () => {
  if (STEALTHISNFT_ENV !== "prod") {
    console.info(`App environment is ${STEALTHISNFT_ENV}`);
  }
}

export const shortenAddress = (address: string, length = 11, separator = "...") => {
  const offset = Math.round((length - separator.length) / 2);
  return address.substring(0, offset) + separator + address.substring(address.length - offset);
}

export const onlyContainsChars = (messageArray: string[]) => {
  return messageArray.every(e => {
    const match = e.match(ALLOWED_CHARS_RE);
    if (match !== null) {
      return match[0] === e;
    }
    return false;
  });
}

export const wordWrap = (text: string, width: number) => {
  return text.replace(
    new RegExp(`(?![^\\n]{1,${width}}$)([^\\n]{1,${width}})\\s`, 'g'), '$1\n'
  );
}

export const decodeBase64DataURI = (dataUri: string) => {
  const b64Data = dataUri.split(',')[1];
  return Buffer.from(b64Data, 'base64').toString('utf8');
}

export const sendGAEvent = (...event: any[]) => {
  //@ts-ignore
  if (window.gtag) gtag(...event);
}